// eslint-disable-next-line import/no-anonymous-default-export
export default {
  home: "Home",
  aboutUs: "About Chauffeur",
  send: "send",
  NewPassword: "New Password",
  Message: "Message",
  ComplaintSuggestions: "Complaints & Suggestions",
  Createaccount: "Create account",
  OrSignup: "Or sign up",
  Signup: "Sign up",
  Orlogin: "Or login",
  Login: "Login",
  OrderDate: "Order Date",
  OrdersCount: "Orders Count",
  Editprofile: "Edit profile",
  Signin: "Login",
  Logout: "Logout",
  EmailAddress: "Email Address",
  Password: "Password",
  Account: "Account",
  contacttype: "contact type",
  deliverycompany: "Delivery company",
  Password8characters: "Password must be greater than 8 characters",
  Password20characters: "Password must be less than 20 characters",
  TelephoneNumber: "Phone number",
  Phonenumber: "Phone",
  submit: "Submit",
  cancelationdate: "Cancelation date",
  CompanyName: "Company Name",
  SendVerificationCode: "Send Verification Code",
  Emailvalid: "The email address is incorrect",
  twopasswords: "The passwords you entered don't match!",
  pleaseselect: "please select ",
  Pleaseinput: "Please input",
  ReenterPassword: "Re-enter Password",
  changepassword: "Change Password",
  currentpassword: "Current Password",
  newpassword: "New Password",
  Thetwopasswords: "The passwords you entered don't match!",
  confirmpassword: "Confirm Password",
  Confirm: "Confirm",
  Forgetpassword: "Forget password",
  FullName: "Full Name",
  Verifynow: "Verify now",
  contactUs: "Contact us",
  EditProfile: "Edit Profile",
  MyOrders: "My Orders",
  MyAdresses: "My addresses",
  MyWallet: " My Wallet",
  Language: "Language",
  Delivery: "Delivery",
  PersonalShopper: "Personal Shopper",
  services: "services",
  footerAboutText: "Chauffeur Brings you Together",
  footerAddress:
    "The 5th Floor of the Business Village Building (B),in  Deira, Office No S84",
  WeAreOnSocialMedia: "We're on social media ",
  Shipping: "Shipping",
  RentCar: "Rent",
  ShareRequirments: "Share your requirements",
  TermsAndCondition: "Terms & Conditions",
  FAQ: "FAQs",
  NewTerm: "New Term",
  NewFAQ: "New FAQs ",
  Addfaq: "Add FAQ",
  Updatefaq: "Update FAQ",
  UpdateCategory: "Update Category",
  ComplaintAndSuggestions: "Complaint & Suggestions",
  Help: "Help",
  PrivacyPolicy: "Privacy Policy",
  MyProfile: "My Profile",
  CopyrightsReservedFor: "Copyrights Reserved for",
  chauffeurHub: "Chauffeur hub",
  curentYear: "2024",
  Company: "Company",
  Profile: "Profile",
  search: "search",
  descriptionAr: "Description (ar)",
  descriptionEn: "Description (en)",
  drivernameAr: "Driver name (ar)",
  drivernameEn: "Driver name (en)",
  nameAr: "Name (ar)",
  nameEn: "Name (en)",
  titleAr: "Title (ar)",
  titleEn: "Title (en)",
  altAr: "Alternative name (ar)",
  altEn: "Alternative name (en)",
  companynameAr: "Company name (ar)",
  companynameEn: "Company name (en)",
  startDate: "Start date",
  Save: "Save",
  mainPhoto: "Main Image",
  country: "Country ",
  Category: "Category ",
  Deliveryfromthecity: "Delivery from the city",
  Deliverytothecity: "Delivery to the city",
  endDate: "End date",
  DoyouWanttodeletethis: "Would you like to cancel this",
  Dashboard: "Dashboard",
  Notifications: "Notifications",
  Orders: "Orders",
  Chauffeurs: "Chauffeurs",
  offers: "offers",
  PackgeSize: "Package Size",
  driverpicture: "Driver picture",
  VehicleType: "Vehicle Type",
  Companyinfo: "Company info",
  NationalId: "Emirates Id",
  VehicleLicence: "Vehicle Licence",
  NewOffer: "New Offer ",
  DrivingLicence: "Driving License",
  NewChauffeur: " New Chauffeur ",
  UpdateChauffeur: "Update Chauffeur ",
  AssignDriver: "Assign Driver ",
  Assign: "Assign ",
  ViewDetails: "View Details ",
  CancelOrder: "Cancel Order ",
  Cancel: " Cancel",
  AddPrice: "Add Price ",
  UpdatePrice: "Update Price ",
  Price: "Price",
  Ok: "Yes ",
  Print: "Print",
  Excel: "Excel",
  cities: "cities ",
  countries: "countries ",
  FAQCategories: "FAQs Categories ",
  Newcity: "New city ",
  city: "city ",
  UpdateCity: "Update City ",
  UpdateCompany: "Update Company",
  NewCompany: "New Company ",
  Notassignedtodriver: "Not assigned to driver ",
  Companies: "Companies",
  NewBlog: "New Blog",
  Blogs: "Blogs",
  Blog: "Blog",
  TermsAndConditions: "Terms and Conditions",
  Reports: "Reports",
  Settings: "Settings",
  Previous: "Previous",
  NewCategory: "New Category ",
  NewCountry: "New Country",
  UpdateCountry: "Update Country",
  cancellations: "cancellations",
  activeAndInActiveCustomersReport: "Active And Inactive Customers",
  activeAndInActiveDeliveriesReport: "Active And Inactive Deliveries",
  CompaniesOrdersActivitesReport: " Active Companies Request",
  Satisfactions: "Satisfactions",
  Payments: "Payments",
  CompaniesOrdersActivites: " Active Companies orders",
  OrderSLA: "Order SLA",
  Next: "Next",
  Amount: "Amount",
  User: "User",
  Rate: "Rate",
  UserType: "User Type",
  orderDelivery: "Order Delivery",
  Actions: "Actions",
  datePlaced: "Date Placed",
  dateMessage: "Message date",
  Status: "Order Status",
  Statuss: "Status",
  Driver: "Driver",
  OrderID: "Order ID",
  Customers: "Customers",
  name: "name",
  active: "active ",
  Advertisements: "Advertisements",
  Advertisement: "Advertisement",
  advertisementimage: "Advertisement image",
  NewAdvertisement: "New Advertisement",
  UpdateAdvertisement: "Update Advertisement",
  description: "description",
  link: "link",
  Upload: "Upload",
  cancelationReport: "Cancelation Report",
  DeliveryCaptainlist: "Delivery Captain list ",
  ThisFieldisrequired: "This Field is required!",
  orderRate: "Order Rate",
  orderRateToDriver: "Rate Customer to Driver",
  orderRateToCustomer: "Rate Driver to Customer",
  ReceiptImage: "Receipt Image",
  LE: "AED",
  SAR: "AED",
  from: "from",
  to: "to",
  CancelationReason: "Cancelation Reason",
  TotalPurchases: "Delivery charge",
  deliveryInformation: "Delivery information",
  delivery: "delivery",
  orderInfo: "Order details",
  deliveryType: "Delivery Type",
  products: "Products",
  deliveryTime: "Delivery Time",
  deliveryNotes: "Delivery Notes",
  deliveryCost: "Delivery Cost",
  VAT: "SERVICE FEES",
  subTotal: "SubTotal",
  total: "Total",
  trackDelivery: "Track Delivery",
  proceedToPayment: "Proceed To Payment",
  packageSize: "Package Size",
  categoryType: "Category Type",
  doYouWantToDeleteThisAddress: "Would you like to cancel this address?",
  myAddresses: "عناويني",
  locationInfo: "Location Info.",
  deliveryCompany: "Delivery Company",
  paymentOwner: "Payment Owner",
  edit: "Edit",
  confirm: "Confirm",
  deliveryService: "Delivery Service",
  DriverInformation: "Driver information",
  CustomerInformation: "Customer information",
  Newsletter: "Newsletter",
  SubscribedDate: "Subscribed Date",
  id: "ID",
  UpdateBlog: "Update Blog",
  RateThisDelivery: "Rate this Delivery",
  Ratedelivery: " Rate Delivery",
  Open: "Open",
  InProgress: "In Progress",
  Closed: "Closed",
  Individual: "Individual",
  Chauffeur: "Chauffeur",
  Corporate: "Corporate",
  ServiceType: "Service Type",
  Standard: "Standard",
  Express: "Express",
  Scheduled: "Scheduled",
  UserName: "User Name",
  UserMobile: "User Mobile",
  UserEmail: "User Email",
  OrderID: "Order ID",
  OrderDate: "Order Date",
  OrderStatus: "Status (completed / canceled)",
  ReceiverInformation: "Receiver Information",
  GeneratePaymentLink: "Generate Payment Link",
  UpdateOrder: "Update order",
  Coupon: "Coupon",
  sendCoupon: "Send Coupon",
  generateCoupon: "Generate Coupon",
  CreationDate: "Creation Date",
  CouponCodeType: "Coupon Type",
  NewCoupon: "New Coupon",
  NameAR: "Name (Ar)",
  NameEN: "Name (EN)",
  Code: "Code",
  ExpirationDate: "Expiration Date",
  MaxUsesPerUser: "Max Uses Per User",
  AllCities: "AllCities",
  AllCompanies: "AllCompanies",
  RedeemAmountOrPercent: "Redeem AmountOrPercent",
  MinOrderAmount: "Min Order Amount",
  FromCity: "From City",
  ToCity: "To City",
  NotificationTemplate: "Notification Template",
  EditNotificationTemplate: "Edit Notification Template",
  CreateNotificationTemplate: "Create Notification Template",
  EmailTemplate: "Email Template",
  CreateEmailTemplate: "Create Email Template",
  EditEmailTemplate: "Edit Email Template",
  MessageEn: "Message (En)",
  MessageAr: "Message (Ar)",
  SelectNotificationType: "Select Notification Type",
  EditNotificationTemplate: "Edit Notification Template",
  EmailType: "Email Type",
  Subject: "Subject",
  SaveTemplate: "Save Template",
  SendPaymentLink: "Send Payment Link",
  Discount: "Discount",
  ReassignCompany: "Reassign Company",
  PaymentLogs: "Payment Logs",
  BlogCategory: "Blog Category",
  deliveryDate: "Delivery Date",
  CreatorName: "Creator Name",
  CreatorContactNo: "Creator Contact Number",
  CancelledBy: "Canceled by ( User, Company, Driver)",
  OrderAmount: "Order Amount",
  CompletedPaidOrNot: "Completed paid or not",
  ReasonofCancelation: "Reason of Cancelation",
  ChauffeurRemarks: "Chauffeur Remarks",
  PaymentInfo: "Payment Info",
  PaymentType: "Payment Type",
  Payer: "Payer",
  ConfirmSendNotificationTemplate: "Do you want to send this notification to all users?"
};
